import React, { memo, useRef } from 'react'
import { Container, styled, useTheme } from '@mui/material'
import { Swiper } from 'swiper/react'
import SwiperCore, { Navigation, FreeMode } from 'swiper'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import 'swiper/css'
import 'swiper/css/navigation'

import Button from '_core/components/atoms/buttons/Button'

interface Props {
  children: React.ReactNode
  keyId: string
}

const Wrapper = styled('div')(({ theme }) => ({
  ['.swiper']: {
    padding: '0 40px',
    [theme.breakpoints.up('lg')]: {
      padding: '0 60px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '0 80px',
    },
  },
  ['.swiper-button-prev, .swiper-button-next']: {
    backgroundImage: 'none !important',
    transition: theme.transitions.create(['opacity', 'background-color']),
    opacity: 1,
    position: 'absolute',
    top: '50%',
    marginTop: '0 !important',
    transform: 'translateY(-50%)',
    zIndex: 10,
    ['&:after']: {
      content: 'none',
      display: 'none',
    },
    ['&.swiper-button-disabled']: {
      opacity: 0,
    },
  },
  ['.swiper-button-prev']: {
    left: 0,
    [theme.breakpoints.up('lg')]: {
      left: '8px',
    },
    [theme.breakpoints.up('xl')]: {
      left: '12px',
    },
  },
  ['.swiper-button-next']: {
    right: 0,
    [theme.breakpoints.up('lg')]: {
      right: '8px',
    },
    [theme.breakpoints.up('xl')]: {
      right: '12px',
    },
  },
}))

const NavSwiper = ({ children }: Props) => {
  const theme = useTheme()
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  SwiperCore.use([Navigation, FreeMode])

  return (
    <Wrapper>
      <Swiper
        onInit={(swiper) => {
          // @ts-ignore
          swiper.params.navigation.prevEl = prevRef.current
          // @ts-ignore
          swiper.params.navigation.nextEl = nextRef.current
          swiper.navigation.destroy()
          swiper.navigation.init()
          swiper.navigation.update()
        }}
        slidesPerView={1}
        spaceBetween={0}
        freeMode={{
          enabled: true,
          sticky: true,
        }}
        observer
        observeParents
        breakpoints={{
          [theme.breakpoints.values.xs]: {
            slidesPerView: 1,
          },
          [theme.breakpoints.values.sm]: {
            slidesPerView: 2,
          },
          [theme.breakpoints.values.md]: {
            slidesPerView: 3,
          },
          [theme.breakpoints.values.lg]: {
            slidesPerView: 4,
          },
          [theme.breakpoints.values.xl]: {
            slidesPerView: 5,
          },
        }}
      >
        {children}
        <Button
          aria-label="previous"
          ref={prevRef}
          className="swiper-button-prev"
          sizeVariant="lg"
          colorVariant="trans-dark"
          round
        >
          <ArrowBack />
        </Button>
        <Button
          aria-label="next"
          ref={nextRef}
          className="swiper-button-next"
          sizeVariant="lg"
          colorVariant="trans-dark"
          round
        >
          <ArrowForward />
        </Button>
      </Swiper>
    </Wrapper>
  )
}

export default memo(NavSwiper)
