import React, { memo } from 'react'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'
import { Box, styled } from '@mui/material'
import { rgba } from 'polished'
import { SwiperSlide } from 'swiper/react'

import Flex from '_core/components/atoms/layout/Flex'
import InternalLink from '_core/components/atoms/links/InternalLink'

import NavSwiper from 'public/components/molecules/swipers/NavSwiper'
import BackgroundImage from 'public/components/atoms/media/BackgroundImage'
import ImageHover from '_core/components/atoms/media/ImageHover'

interface Props {
  terms: [
    | Queries.DatoCmsTermDepartment
    | Queries.DatoCmsTermRegion
    | Queries.DatoCmsTermLevel
    | Queries.DatoCmsTermLocation
  ]
  keyId: string
  heading: string
  lastChild?: boolean
}

const Card = styled('div')(({ theme }) => ({
  margin: '0 5px',
  backgroundColor: theme.palette.secondary.main,
  fontWeight: theme.typography.fontWeightSemiBold,
  borderRadius: theme.shape?.borderRadius,
  overflow: 'hidden',
  a: {
    color: 'white',
  },
}))

const AspectRatio = styled('div')(({ theme }) => ({
  height: 0,
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  paddingTop: '75%',
  [theme.breakpoints.up('xl')]: {
    paddingTop: '60%',
  },
  ['@media not all and (min-resolution:.001dpcm)']: {
    ['@media']: {
      height: '100% !important',
      paddingTop: '0 !important',
    },
  },
}))

const StyledImageHover = styled(ImageHover)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  ['@media not all and (min-resolution:.001dpcm)']: {
    ['@media']: {
      position: 'relative !important',
    },
  },
})

const StyledImage = styled(GatsbyImage)({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%',
  ['@media not all and (min-resolution:.001dpcm)']: {
    ['@media']: {
      position: 'relative !important',
    },
  },
})

const StyledInternalLink = styled(InternalLink)(({ theme }) => ({}))
const Content = styled(Flex)(({ theme }) => ({
  background: `linear-gradient(0deg, ${rgba(
    theme.palette.secondary.main,
    1
  )} 0%, ${rgba(theme.palette.secondary.main, 0)} 70%, ${rgba(
    theme.palette.secondary.main,
    0
  )} 100%)`,
}))

const TermSlider = ({ terms, keyId, heading, lastChild }: Props) => {
  let wrapperProps = {}
  if (lastChild) {
    wrapperProps = { mb: { xs: 5, sm: 6, xl: 9 } }
  }
  return (
    <Box {...wrapperProps}>
      <Box
        component="h3"
        sx={{
          typography: 'h4',
          m: 0,
          pt: { xs: 4, sm: 5, lg: 5, xl: 8 },
          pb: { xs: 2, sm: 2, lg: 2, xl: 3 },
          ml: { xs: 4, sm: 4, lg: 6, xl: 8 },
        }}
      >
        {heading}
      </Box>
      <NavSwiper keyId={keyId}>
        {terms.map(
          (
            item:
              | Queries.DatoCmsTermDepartment
              | Queries.DatoCmsTermRegion
              | Queries.DatoCmsTermLevel
              | Queries.DatoCmsTermLocation
          ) => {
            const term = item.node
            return (
              <SwiperSlide key={term.originalId}>
                <Card>
                  <AspectRatio>
                    <StyledImageHover>
                      {term.image && (
                        <StyledImage
                          image={term.image.gatsbyImageData}
                          alt=""
                        />
                      )}
                      <StyledInternalLink
                        slug={term.slug}
                        apiKey={term.model.apiKey}
                      >
                        <Content full alignItems="flex-end" position="relative">
                          <Box px="25px" py={2}>
                            {term.name}
                          </Box>
                        </Content>
                      </StyledInternalLink>
                    </StyledImageHover>
                  </AspectRatio>
                </Card>
              </SwiperSlide>
            )
          }
        )}
      </NavSwiper>
    </Box>
  )
}

export default memo(TermSlider)
