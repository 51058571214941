import React from 'react'
import { graphql } from 'gatsby'

import termStructure, { termProps } from '_core/constants/term-structure'
import Section from '_core/components/atoms/layout/Section'

import Layout from 'public/components/organisms/global/Layout'
import PageTitleHero from 'public/components/organisms/hero/PageTitleHero'
import TextSection from 'public/components/organisms/sections/TextSection'
import TermSlider from 'public/components/organisms/sections/TermSlider'

interface Props {
  data: Queries.TermLandingQuery
}

const TeamLanding = ({ data }: Props) => {
  const page = data.datoCmsLandingPage

  return (
    <Layout
      metadata={{
        seoTags: page.seoMetaTags,
      }}
    >
      <PageTitleHero
        mainHeading={page.heroMainHeading}
        smallHeading={page.heroSmallHeading}
        imageLandscape={page.imageLandscape.gatsbyImageData}
        imagePortrait={page.imageLandscape.gatsbyImageData}
      />
      <TextSection
        leadText={page.textSectionHeroText}
        smallHeading={page.textSectionSmallHeading}
        text={page.textSectionBodyNode.childMarkdownRemark.html}
      />
      <Section bgcolor="lightGrey">
        {Object.keys(termStructure.primary).map((key, index) => {
          const term: termProps =
            termStructure.primary[key as keyof typeof termStructure.primary]
          if (data[`allDatoCmsTerm${term.dataSelector}`].edges.length > 0) {
            return (
              <TermSlider
                keyId={`carousel-${key}`}
                key={`term-slider-${key}`}
                terms={data[`allDatoCmsTerm${term.dataSelector}`].edges}
                heading={term.name}
                lastChild={
                  Object.keys(termStructure.primary).length === index + 1
                    ? true
                    : undefined
                }
              />
            )
          }
        })}
      </Section>
    </Layout>
  )
}

export default TeamLanding

export const pageQuery = graphql`
  query TermLanding {
    datoCmsLandingPage(originalId: { eq: "1392098" }) {
      ...LandingPageTitleHero
      textSectionBodyNode {
        childMarkdownRemark {
          html
        }
      }
      textSectionHeroText
      textSectionSmallHeading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsTermDepartment(sort: { order: ASC, fields: position }) {
      edges {
        node {
          originalId
          model {
            apiKey
          }
          name
          position
          slug
          image {
            gatsbyImageData(
              width: 500
              layout: CONSTRAINED
              imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
            )
          }
        }
      }
    }
    allDatoCmsTermRegion(sort: { order: ASC, fields: position }) {
      edges {
        node {
          originalId
          model {
            apiKey
          }
          name
          position
          slug
          image {
            gatsbyImageData(
              width: 500
              layout: CONSTRAINED
              imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
            )
          }
        }
      }
    }
    allDatoCmsTermLevel(sort: { order: ASC, fields: position }) {
      edges {
        node {
          originalId
          model {
            apiKey
          }
          name
          position
          slug
          image {
            gatsbyImageData(
              width: 500
              layout: CONSTRAINED
              imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
            )
          }
        }
      }
    }
    allDatoCmsTermLocation(sort: { order: ASC, fields: position }) {
      edges {
        node {
          originalId
          model {
            apiKey
          }
          name
          position
          slug
          image {
            gatsbyImageData(
              width: 500
              layout: CONSTRAINED
              imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
            )
          }
        }
      }
    }
  }
`
